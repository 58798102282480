import type { IControllerAppData } from '@wix/yoshi-flow-editor';
import { demoRichContent, paywallDemoRichContent } from './demo-rich-content';
import { postContentElementIds } from './elementIds';
import model from './model';

const getPostRichContentData = async ({
  isEditor,
  appData,
  state,
}: {
  isEditor: boolean;
  appData: IControllerAppData | undefined;
  state: $w.State;
}) => {
  if (isEditor) {
    return {
      richContent:
        state.id === postContentElementIds.paidContentId
          ? paywallDemoRichContent
          : demoRichContent,
    };
  }

  const { post } = await appData?.fetchPostPageRenderModel();
  return {
    richContent: post.richContent,
    shouldRenderPaywall: post.preview,
  };
};

export default model.createController(({ $w, flowAPI, appData }) => {
  let isPageReady = false;
  return {
    pageReady: async () => {
      if (isPageReady) {
        return;
      }

      isPageReady = true;

      const richContentViewer = $w(
        `#${postContentElementIds.richContentViewerId}`,
      ) as $w.RichContentViewer;

      const richContentViewerWithinPaidContent = $w(
        `#${postContentElementIds.richContentViewerWithinPaidContentId}`,
      ) as $w.RichContentViewer;

      const paywallRichContentViewer = $w(
        `#${postContentElementIds.paywallRichContentViewerId}`,
      ) as $w.RichContentViewer;
      paywallRichContentViewer.delete();

      const multiStateBox = $w(
        `#${postContentElementIds.multiStateBoxId}`,
      ) as $w.MultiStateBox;

      const metaSiteId =
        flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
      const isMobileView = flowAPI.environment.isMobile;

      const { richContent } = await getPostRichContentData({
        isEditor: flowAPI.environment.isEditor,
        appData,
        state: multiStateBox.currentState,
      });

      richContentViewer.metaSiteId = metaSiteId;
      richContentViewer.isMobileView = isMobileView;
      richContentViewer.content = richContent;

      richContentViewerWithinPaidContent.metaSiteId = metaSiteId;
      richContentViewerWithinPaidContent.isMobileView = isMobileView;
      richContentViewerWithinPaidContent.content = richContent;
    },
    exports: {},
  };
});
